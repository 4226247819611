import React from "react";
import images from "./../../../helper/images";
import { Link, useNavigate } from "react-router-dom";
import constant, { TemplateStatus } from "./../../../helper/constant";
import { useAIModelContext } from "../../../context/ai-model.context";
import {
  createNewTemplateData,
  formatDate,
  getTimeStamp,
} from "../../../helper/helper";

const RecentModelItem = (props) => {
  const { template } = props;
  const navigate = useNavigate();
  const { clearModelInfo, createTemplateWithoutData, deleteDraftTemplate } =
    useAIModelContext();

  const getStatusImage = (status) => {
    if (status === TemplateStatus.COMPLETED)
      return (
        <>
          <div className="edtmdlbtn greenbg px-2 position-relative"></div>
          <div className="edtmdlbtn-icon position-absolute">
            <svg
              width="18"
              height="18"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.6443 1.34766L9.10566 15.242L2.875 9.01582L0 11.8908L9.58184 21.4727L23 4.22266L19.6443 1.34766Z"
                fill="white"
              />
            </svg>
          </div>
        </>
      );
    if (status === TemplateStatus.SCHEDULED)
      return (
        <>
          <div className="edtmdlbtn yellowbg px-2 position-relative"></div>
          <div className="edtmdlbtn-icon position-absolute">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389956 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9975 6.61382 17.0485 4.3261 15.3612 2.63882C13.6739 0.95154 11.3862 0.00251984 9 0ZM13.8462 9.69231H9C8.81639 9.69231 8.6403 9.61937 8.51047 9.48953C8.38063 9.3597 8.30769 9.18361 8.30769 9V4.15385C8.30769 3.97023 8.38063 3.79414 8.51047 3.66431C8.6403 3.53448 8.81639 3.46154 9 3.46154C9.18361 3.46154 9.3597 3.53448 9.48954 3.66431C9.61937 3.79414 9.69231 3.97023 9.69231 4.15385V8.30769H13.8462C14.0298 8.30769 14.2059 8.38063 14.3357 8.51046C14.4655 8.6403 14.5385 8.81639 14.5385 9C14.5385 9.18361 14.4655 9.3597 14.3357 9.48953C14.2059 9.61937 14.0298 9.69231 13.8462 9.69231Z"
                fill="#FFFEFE"
              />
            </svg>
          </div>
        </>
      );

    return (
      <>
        <div className="edtmdlbtn bluebg px-2 position-relative"></div>
        <div className="edtmdlbtn-icon position-absolute">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7368 11.4876L16.6776 15.548C16.7101 15.6964 16.709 15.8502 16.6744 15.9982C16.6398 16.1461 16.5726 16.2844 16.4777 16.4031C16.3827 16.5217 16.2625 16.6177 16.1258 16.6839C15.989 16.7501 15.8392 16.785 15.6873 16.7859C15.6165 16.793 15.5452 16.793 15.4744 16.7859L11.3893 15.8451L3.5459 8.0314L7.92315 3.66406L15.7368 11.4876Z"
              fill="white"
            />
            <path
              d="M1.07982 4.12023L4.00128 1.19877C4.19335 1.00767 4.45327 0.900391 4.72422 0.900391C4.99516 0.900391 5.25508 1.00767 5.44715 1.19877L7.07129 2.8229L2.699 7.19519L1.07486 5.57106C0.979817 5.47553 0.904542 5.3622 0.853343 5.23755C0.802144 5.1129 0.776027 4.97937 0.776487 4.84462C0.776947 4.70986 0.803974 4.57652 0.856023 4.45222C0.908072 4.32792 0.98412 4.21511 1.07982 4.12023Z"
              fill="white"
            />
          </svg>
        </div>
      </>
    );
  };

  const getNavigationLink = (template) => {
    if (template.status === TemplateStatus.COMPLETED)
      return `${constant.PAGES.AI_PREDICTION}/${template.id}`;
    if (template.status === TemplateStatus.SCHEDULED)
      return `${constant.PAGES.HOME.AI_MODEL}/${template.id}`;
    return `${constant.PAGES.HOME.AI_WORKFLOW}/${template.id}`;
  };

  const duplicateTemplate = () => {
    const data = { ...template };
    const newTemplate = createNewTemplateData(data);
    newTemplate.template_name = `${newTemplate.template_name}-${getTimeStamp()}`;
    createTemplateWithoutData(newTemplate, (id) => {
      navigate(`/${constant.PAGES.HOME.AI_WORKFLOW}/${id}`);
    });
  };

  const changePage = () => {
    clearModelInfo();
  };

  const deleteTemplate = (temp) => {
    deleteDraftTemplate(temp.id);
  };

  const render = () => {
    const { template_name, status, url, id, updatedAt, createdAt } = template;
    const activeImaegStyle = {
      backgroundImage: `url(${url})`,
      backgroundPosition: "center",
      backgroundSize: "contain",
    };
    return (
      <div className="col-sm-12 col-md-6 col-lg-4 my-3 text-center px-3">
        <div className="recentmodelbox" style={url ? activeImaegStyle : {}}>
          <div className="d-flex justify-content-between">
            <Link onClick={() => changePage()} to={getNavigationLink(template)}>
              {getStatusImage(status)}
            </Link>
            {/* <Link onClick={() => changePage()} to={getNavigationLink(template)}><img src={getStatusImage(status)} width="50px" alt="new/edit" /></Link> */}
            <div className="p-2 dropdown">
              <div
                className="cursor-pointer"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg width="10" height="27" viewBox="0 0 15 37" fill="none">
                  <g>
                    <circle cx="7.5" cy="3.5" r="3.5" fill="#217CE8" />
                    <circle cx="7.5" cy="14.5" r="3.5" fill="#217CE8" />
                    <circle cx="7.5" cy="25.5" r="3.5" fill="#217CE8" />
                  </g>
                </svg>
              </div>
              <div
                className="dropdown-menu font-14 py-0"
                aria-labelledby="dropdownMenuButton"
                x-placement="bottom-start"
              >
                {(status === TemplateStatus.NEW ||
                  status === TemplateStatus.DRAFT) && (
                  <Link
                    className="dropdown-item"
                    onClick={() => changePage()}
                    to={`${constant.PAGES.HOME.AI_WORKFLOW}/${template.id}`}
                  >
                    <span className="mr-2">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M12.8857 2.19512C12.6806 1.9794 12.4343 1.80692 12.1615 1.68782C11.8887 1.56872 11.5948 1.50542 11.2971 1.50164C10.9994 1.49787 10.704 1.5537 10.4283 1.66584C10.1525 1.77798 9.902 1.94417 9.69145 2.15462L2.6602 9.18587C2.41444 9.43156 2.24377 9.74219 2.1682 10.0814L1.51045 13.0439C1.49673 13.1054 1.49875 13.1695 1.51634 13.23C1.53393 13.2906 1.56651 13.3458 1.61108 13.3904C1.65564 13.4351 1.71075 13.4677 1.77129 13.4854C1.83183 13.5031 1.89586 13.5052 1.95745 13.4916L4.9027 12.8369C5.25328 12.7588 5.57433 12.5824 5.8282 12.3284L12.8467 5.30987C13.2581 4.8983 13.4924 4.3423 13.4997 3.76043C13.507 3.17856 13.2867 2.61686 12.8857 2.19512ZM10.2217 2.68487C10.3589 2.54571 10.5223 2.43506 10.7024 2.35931C10.8825 2.28356 11.0759 2.2442 11.2713 2.2435C11.4667 2.2428 11.6603 2.28077 11.841 2.35523C12.0217 2.42969 12.1858 2.53916 12.324 2.67734C12.4622 2.81552 12.5716 2.97967 12.6461 3.16034C12.7206 3.34102 12.7585 3.53464 12.7578 3.73005C12.7571 3.92546 12.7178 4.11881 12.642 4.29894C12.5663 4.47907 12.4556 4.64244 12.3165 4.77962L11.8132 5.28287L9.71845 3.18887L10.2217 2.68487ZM9.1882 3.71987L11.283 5.81387L5.29795 11.7989C5.14491 11.9521 4.95134 12.0585 4.73995 12.1056L2.36995 12.6321L2.90095 10.2449C2.9452 10.0446 3.0457 9.86162 3.19045 9.71687L9.1882 3.71987Z"
                          fill="#474646"
                          fillOpacity="0.5"
                        />
                      </svg>
                    </span>{" "}
                    Edit
                  </Link>
                )}
                {status === TemplateStatus.SCHEDULED && (
                  <Link
                    className="dropdown-item"
                    onClick={() => changePage()}
                    to={`${constant.PAGES.HOME.AI_MODEL}/${template.id}`}
                  >
                    <span className="mr-2">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M12.8857 2.19512C12.6806 1.9794 12.4343 1.80692 12.1615 1.68782C11.8887 1.56872 11.5948 1.50542 11.2971 1.50164C10.9994 1.49787 10.704 1.5537 10.4283 1.66584C10.1525 1.77798 9.902 1.94417 9.69145 2.15462L2.6602 9.18587C2.41444 9.43156 2.24377 9.74219 2.1682 10.0814L1.51045 13.0439C1.49673 13.1054 1.49875 13.1695 1.51634 13.23C1.53393 13.2906 1.56651 13.3458 1.61108 13.3904C1.65564 13.4351 1.71075 13.4677 1.77129 13.4854C1.83183 13.5031 1.89586 13.5052 1.95745 13.4916L4.9027 12.8369C5.25328 12.7588 5.57433 12.5824 5.8282 12.3284L12.8467 5.30987C13.2581 4.8983 13.4924 4.3423 13.4997 3.76043C13.507 3.17856 13.2867 2.61686 12.8857 2.19512ZM10.2217 2.68487C10.3589 2.54571 10.5223 2.43506 10.7024 2.35931C10.8825 2.28356 11.0759 2.2442 11.2713 2.2435C11.4667 2.2428 11.6603 2.28077 11.841 2.35523C12.0217 2.42969 12.1858 2.53916 12.324 2.67734C12.4622 2.81552 12.5716 2.97967 12.6461 3.16034C12.7206 3.34102 12.7585 3.53464 12.7578 3.73005C12.7571 3.92546 12.7178 4.11881 12.642 4.29894C12.5663 4.47907 12.4556 4.64244 12.3165 4.77962L11.8132 5.28287L9.71845 3.18887L10.2217 2.68487ZM9.1882 3.71987L11.283 5.81387L5.29795 11.7989C5.14491 11.9521 4.95134 12.0585 4.73995 12.1056L2.36995 12.6321L2.90095 10.2449C2.9452 10.0446 3.0457 9.86162 3.19045 9.71687L9.1882 3.71987Z"
                          fill="#474646"
                          fillOpacity="0.5"
                        />
                      </svg>
                    </span>{" "}
                    View
                  </Link>
                )}
                {status === TemplateStatus.COMPLETED && (
                  <Link
                    className="dropdown-item"
                    onClick={() => changePage()}
                    to={`${constant.PAGES.AI_PREDICTION}/${template.id}`}
                  >
                    <span className="mr-2">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M12.8857 2.19512C12.6806 1.9794 12.4343 1.80692 12.1615 1.68782C11.8887 1.56872 11.5948 1.50542 11.2971 1.50164C10.9994 1.49787 10.704 1.5537 10.4283 1.66584C10.1525 1.77798 9.902 1.94417 9.69145 2.15462L2.6602 9.18587C2.41444 9.43156 2.24377 9.74219 2.1682 10.0814L1.51045 13.0439C1.49673 13.1054 1.49875 13.1695 1.51634 13.23C1.53393 13.2906 1.56651 13.3458 1.61108 13.3904C1.65564 13.4351 1.71075 13.4677 1.77129 13.4854C1.83183 13.5031 1.89586 13.5052 1.95745 13.4916L4.9027 12.8369C5.25328 12.7588 5.57433 12.5824 5.8282 12.3284L12.8467 5.30987C13.2581 4.8983 13.4924 4.3423 13.4997 3.76043C13.507 3.17856 13.2867 2.61686 12.8857 2.19512ZM10.2217 2.68487C10.3589 2.54571 10.5223 2.43506 10.7024 2.35931C10.8825 2.28356 11.0759 2.2442 11.2713 2.2435C11.4667 2.2428 11.6603 2.28077 11.841 2.35523C12.0217 2.42969 12.1858 2.53916 12.324 2.67734C12.4622 2.81552 12.5716 2.97967 12.6461 3.16034C12.7206 3.34102 12.7585 3.53464 12.7578 3.73005C12.7571 3.92546 12.7178 4.11881 12.642 4.29894C12.5663 4.47907 12.4556 4.64244 12.3165 4.77962L11.8132 5.28287L9.71845 3.18887L10.2217 2.68487ZM9.1882 3.71987L11.283 5.81387L5.29795 11.7989C5.14491 11.9521 4.95134 12.0585 4.73995 12.1056L2.36995 12.6321L2.90095 10.2449C2.9452 10.0446 3.0457 9.86162 3.19045 9.71687L9.1882 3.71987Z"
                          fill="#474646"
                          fillOpacity="0.5"
                        />
                      </svg>
                    </span>{" "}
                    View
                  </Link>
                )}
                <Link
                  className="dropdown-item"
                  onClick={() => duplicateTemplate()}
                >
                  <span className="mr-2">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                      <g clipPath="url(#clip0_194_777)">
                        <path
                          d="M13.125 4.6875V13.125H4.6875V4.6875H13.125ZM13.125 3.75H4.6875C4.43886 3.75 4.2004 3.84877 4.02459 4.02459C3.84877 4.2004 3.75 4.43886 3.75 4.6875V13.125C3.75 13.3736 3.84877 13.6121 4.02459 13.7879C4.2004 13.9637 4.43886 14.0625 4.6875 14.0625H13.125C13.3736 14.0625 13.6121 13.9637 13.7879 13.7879C13.9637 13.6121 14.0625 13.3736 14.0625 13.125V4.6875C14.0625 4.43886 13.9637 4.2004 13.7879 4.02459C13.6121 3.84877 13.3736 3.75 13.125 3.75Z"
                          fill="#474646"
                          fillOpacity="0.5"
                        />
                        <path
                          d="M1.875 8.4375H0.9375V1.875C0.9375 1.62636 1.03627 1.3879 1.21209 1.21209C1.3879 1.03627 1.62636 0.9375 1.875 0.9375H8.4375V1.875H1.875V8.4375Z"
                          fill="#474646"
                          fillOpacity="0.5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_194_777">
                          <rect width="15" height="15" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>{" "}
                  Copy / Duplicate
                </Link>

                {(status === TemplateStatus.NEW ||
                  status === TemplateStatus.DRAFT) && (
                  <Link
                    className="dropdown-item"
                    onClick={() => deleteTemplate(template)}
                  >
                    <span className="mr-2">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_194_780)">
                          <path
                            d="M4.5 3V1.5C4.5 1.23478 4.60536 0.98043 4.79289 0.792893C4.98043 0.605357 5.23478 0.5 5.5 0.5H9.5C9.76522 0.5 10.0196 0.605357 10.2071 0.792893C10.3946 0.98043 10.5 1.23478 10.5 1.5V3M0 3.5H15M1.5 3.5V13.5C1.5 13.7652 1.60536 14.0196 1.79289 14.2071C1.98043 14.3946 2.23478 14.5 2.5 14.5H12.5C12.7652 14.5 13.0196 14.3946 13.2071 14.2071C13.3946 14.0196 13.5 13.7652 13.5 13.5V3.5M7.5 7V12M4.5 9V12M10.5 9V12"
                            stroke="#474646"
                            strokeOpacity="0.5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_194_780">
                            <rect width="15" height="15" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Delete
                  </Link>
                )}
              </div>
            </div>
          </div>
          {(status === TemplateStatus.NEW ||
            status === TemplateStatus.DRAFT) && (
            <small className="px-3 text-secondary">
              Model Preview will generate just after starting Deployment
            </small>
          )}
          <div className="modelupdatebox mt-auto">
            <div>
              <b>Model Name:</b> {template_name}
            </div>
            <div className="mt-1 text-secondary">
              <div>
                <b>Created:</b> {formatDate(createdAt, constant.dateTimeFormat)}
              </div>
              <div>
                <b>Last Modified:</b>{" "}
                {formatDate(updatedAt, constant.dateTimeFormat)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

export default RecentModelItem;
