import React, { useEffect, useState } from "react";
import { getModelDetailForId } from "../../../service/ai-model.service";

const AIModelDescription = (props) => {
  const { modelId } = props;
  const [modelDetail, setModelDetail] = useState(null);

  useEffect(() => {
    getModelDetailForId(modelId)
      .then((r) => {
        if (r) setModelDetail(r);
      })
      .catch((ex) => console.log(ex));
  }, [modelId]);

  const render = () => {
    if (modelDetail)
      return (
        <div
          className="ai-model-box mt-0 mb-2"
          dangerouslySetInnerHTML={{ __html: modelDetail.model_html }}
        ></div>
      );
  };
  return render();
};

export default AIModelDescription;
