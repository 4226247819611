import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { BasePageContainer } from "../../components/common";
import CustomLoader from "../../components/common/CustomLoader";
import Protected from "../../components/common/Protected";
import { useAIModelContext } from "../../context/ai-model.context";
import { useAppContext } from "../../context/app.context";

const DashboardPage = () => {
  const { showLeftMenu } = useAppContext();
  const { isLoading } = useAIModelContext();

  return (
    <>
      {isLoading && <CustomLoader full />}
      <Protected>
        <BasePageContainer>
          <section className={`dashboardwrap ${!showLeftMenu ? "active" : ""}`}>
            <Outlet />
          </section>
        </BasePageContainer>
      </Protected>
    </>
  );
};

export default DashboardPage;
