import PageNotFound from "../components/common/PageNotFound";
import AIData from "../components/home/ai-data/AIData";
import AIModel from "../components/home/ai-model/AIModel";
import AIPrediction from "../components/home/ai-prediction/AIPrediction";
import AIRoadMap from "../components/home/ai-roadmap/AIRoadMap";
import AIValue from "../components/home/ai-value/AIValue";
import AIWorkflow from "../components/home/ai-workflow/AIWorkflow";
import TemplateForm from "../components/home/ai-workflow/TemplateForm";
import BusinessOverview from "../components/home/business-overview/BusinessOverview";
import Home from "../components/home/Home";
import ProfilePage from "../components/home/user/ProfilePage";
import UserManagement from "../components/home/user/UserManagement";
import constant from "../helper/constant";
import LoginPage from "../pages/auth/LoginPage";
import UnsignedPage from "../pages/auth/UnsignedPage";
import UnsignedReportPage from "../pages/auth/UnsignedReportPage";
import CreateOrgPage from "../pages/home/CreateOrgPage";
import DashboardPage from "../pages/home/DashboardPage";

const authRoutes = [
  {
    path: ``,
    element: <UnsignedPage />,
  },
  {
    path: `${constant.PAGES.AUTH.VALUE_CALCULATOR_REPORT}`,
    element: <UnsignedReportPage />,
  },
  {
    path: `${constant.PAGES.AUTH.LOGIN}`,
    element: <LoginPage />,
  },
  {
    path: `*`,
    element: <LoginPage />,
  },
];
const appRoutes = [
  {
    path: ``,
    element: <DashboardPage />,
    children: [
      {
        path: ``,
        element: <Home />,
      },
      {
        path: `${constant.PAGES.HOME.BUSINESS_OVERVIEW}`,
        element: <BusinessOverview />,
      },
      {
        path: `${constant.PAGES.HOME.PROFILE}`,
        element: <ProfilePage />,
      },
      {
        path: `${constant.PAGES.HOME.USER_MANAGEMENT}`,
        element: <UserManagement />,
      },
      {
        path: `${constant.PAGES.HOME.AI_TEMPLATE}`,
        element: <TemplateForm />,
      },
      {
        path: `${constant.PAGES.HOME.AI_WORKFLOW}/:id`,
        element: <AIWorkflow />,
      },
      {
        path: `${constant.PAGES.HOME.AI_VALUE}/:id`,
        element: <AIValue />,
      },
      {
        path: `${constant.PAGES.HOME.AI_ROADMAP}/:id`,
        element: <AIRoadMap />,
      },
      {
        path: `${constant.PAGES.HOME.AI_DATA}/:id`,
        element: <AIData />,
      },
      {
        path: `${constant.PAGES.HOME.AI_MODEL}/:id`,
        element: <AIModel />,
      },
      {
        path: `${constant.PAGES.AI_PREDICTION}/:id/*`,
        element: <AIPrediction />,
      },
    ],
  },
  {
    path: `${constant.PAGES.CREATE_ORG}`,
    element: <CreateOrgPage />,
  },
  {
    path: `*`,
    element: <PageNotFound />,
  },
];
export { authRoutes, appRoutes };
