import React, { useEffect, useState } from "react";
import ModalComponent from "../../../common/ModalComponent";
import constant, { ModelType } from "../../../../helper/constant";
import { useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../context/ai-model.context";
import SocialMediaResult from "./social/SocialMediaResult";
import RegressionResult from "./regression/RegressionResult";
import ImageClassificationAI from "./image-classification/ImageClassificationAI";
import DocumentAI from "./document-ai/DocumentAI";
import KnowledgeAI from "./knowledge-ai/KnowledgeAI";
import RegressionExplainable from "./regression/RegressionExplainable";
import TimeSeriesExplainable from "./regression/TimeSeriesExplainable";

const AIPredictionResult = () => {
  const params = useParams();
  const { getAIPredictData, selectedPredictionModel, predictionData } =
    useAIModelContext();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (params.id) {
      getAIPredictData(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (selectedPredictionModel) setShowModal(true);
    else setShowModal(false);
  }, [selectedPredictionModel]);

  const onModalClose = () => {
    console.log("close");
    setShowModal(false);
  };

  const renderConditionalResults = () => {
    if (showModal) {
      const { modelId } = selectedPredictionModel;
      switch (modelId) {
        case ModelType.SocialMedia:
          return <SocialMediaResult />;
        case ModelType.TabularRegression:
        case ModelType.TabularClassification:
        case ModelType.TimeSerialTabular:
          return <RegressionResult />;
        case ModelType.ImageClassification:
          return <ImageClassificationAI />;
        case ModelType.DocumentAI:
          return <DocumentAI />;
        case ModelType.KnowledgeAI:
          return <KnowledgeAI />;
        default:
          return (
            <div className="d-flex justify-content-center align-items-center">
              <p className="">Comming soon ...</p>
            </div>
          );
      }
    }
  };

  const renderDynamicExplainable = () => {
    if (showModal) {
      const { modelId } = selectedPredictionModel;
      switch (modelId) {
        case ModelType.TabularClassification:
        case ModelType.TabularRegression:
          return <RegressionExplainable />;
        case ModelType.TimeSerialTabular:
          return <TimeSeriesExplainable />;
        default:
          return (
            <div className="d-flex justify-content-center align-items-center">
              <p className="">Comming soon ...</p>
            </div>
          );
      }
    }
  };

  const renderPredictionTabs = () => {
    return (
      <div className="popuptabs">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              AI Prediction
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Explainable AI
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active mt-2"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {renderConditionalResults()}
          </div>
          <div
            className="tab-pane fade mt-2"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {renderDynamicExplainable()}
          </div>
        </div>
      </div>
    );
  };

  const render = () => {
    if (selectedPredictionModel) {
      const { model_name } = selectedPredictionModel;
      return (
        <ModalComponent
          navigateTo={`/${constant.PAGES.AI_PREDICTION}/${params.id}`}
          full
          title={`${model_name}`}
          show={showModal}
          handleClose={onModalClose}
        >
          {renderPredictionTabs()}
        </ModalComponent>
      );
    }
  };
  return render();
};

export default AIPredictionResult;
