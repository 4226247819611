import { faClose, faCross } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const ModalComponent = (props) => {
  const { show, handleClose, autoClose, full, title, navigateTo, height } =
    props;

  const render = () => {
    return (
      <Modal
        keyboard={autoClose}
        dialogClassName={full ? "full-width-modal" : ""}
        className="center"
        show={show}
        centered
        onHide={handleClose}
        backdrop={autoClose ? autoClose : "static"}
      >
        {title && (
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
            <Link
              {...(navigateTo ? { to: navigateTo } : {})}
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faClose} />
            </Link>
          </Modal.Header>
        )}
        <Modal.Body style={{ height }}>{props.children}</Modal.Body>
      </Modal>
    );
  };

  return render();
};

export default ModalComponent;
