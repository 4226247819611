import React, { useEffect, useRef, useState } from "react";
import {
  deleteChatFile,
  getChatbotPastFiles,
  getSignedFile,
  uploadChatbotFile,
} from "../../../service/ai-model.service";
import constant, { FILE_TYPE, ToastType } from "../../../helper/constant";
import ModalComponent from "./../../common/ModalComponent";
import CustomLoader from "./../../common/CustomLoader";
import { Link } from "react-router-dom";
import { useAppContext } from "./../../../context/app.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";

const ChatbotFiles = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [url, setUrl] = useState("");
  const { showAlert } = useAppContext();
  const [newFile, setNewFile] = useState(false);
  const fileRef = useRef();
  const { showModal } = useAIAskMeContext();

  useEffect(() => {
    if (showModal && refresh) {
      setLoading(true);
      getChatbotPastFiles()
        .then((response) => {
          if (response && response.result && response.result.length) {
            setFileList(response.result);
          }
          resetFields();
        })
        .finally(() => {
          setRefresh(false);
          setLoading(false);
        });
    }
  }, [showModal, refresh]);

  const resetFields = () => {
    setUrl("");
    setNewFile(null);
    if (fileRef.current) fileRef.current.value = "";
  };

  useEffect(() => {
    if (newFile) {
      onFileConfirm();
    }
  }, [newFile]);

  const getFileImage = (type) => {
    switch (true) {
      case FILE_TYPE.PDF.includes(type):
        return (
          <svg
            width="27"
            height="27"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.0999 2.07227L29.6639 7.87227V29.9283H8.87891V30.0003H29.7349V7.94527L24.0999 2.07227Z"
              fill="#909090"
            />
            <path
              d="M24.0311 2H8.80811V29.928H29.6641V7.873L24.0311 2Z"
              fill="#F4F4F4"
            />
            <path
              d="M8.65514 3.5H2.26514V10.327H22.3651V3.5H8.65514Z"
              fill="#7A7B7C"
            />
            <path
              d="M22.472 10.2109H2.39502V3.37891H22.472V10.2109Z"
              fill="#DD2025"
            />
            <path
              d="M9.05212 4.53467H7.74512V9.33467H8.77312V7.71567L9.00012 7.72867C9.22069 7.72488 9.43919 7.68537 9.64712 7.61167C9.82942 7.54897 9.99713 7.44998 10.1401 7.32067C10.2856 7.19749 10.4003 7.04203 10.4751 6.86667C10.5754 6.57517 10.6112 6.26537 10.5801 5.95867C10.5739 5.73958 10.5355 5.5226 10.4661 5.31467C10.403 5.16458 10.3093 5.02927 10.191 4.91737C10.0728 4.80546 9.93247 4.7194 9.77912 4.66467C9.64653 4.61667 9.50953 4.58184 9.37012 4.56067C9.26454 4.54438 9.15794 4.53569 9.05112 4.53467M8.86212 6.82867H8.77312V5.34867H8.96612C9.0513 5.34253 9.13677 5.35561 9.21622 5.38694C9.29566 5.41826 9.36706 5.46704 9.42512 5.52967C9.54544 5.69069 9.60971 5.88668 9.60812 6.08767C9.60812 6.33367 9.60812 6.55667 9.38612 6.71367C9.22618 6.80164 9.04414 6.84224 8.86212 6.82867ZM12.5331 4.52167C12.4221 4.52167 12.3141 4.52967 12.2381 4.53267L12.0001 4.53867H11.2201V9.33867H12.1381C12.4889 9.34829 12.8382 9.28883 13.1661 9.16367C13.43 9.05899 13.6637 8.89025 13.8461 8.67267C14.0235 8.45313 14.1508 8.19751 14.2191 7.92367C14.2977 7.61354 14.336 7.29459 14.3331 6.97467C14.3525 6.59683 14.3233 6.21806 14.2461 5.84767C14.1729 5.57504 14.0358 5.32377 13.8461 5.11467C13.6973 4.94584 13.5152 4.80964 13.3111 4.71467C13.1359 4.63359 12.9516 4.57383 12.7621 4.53667C12.6868 4.52423 12.6105 4.51854 12.5341 4.51967M12.3521 8.45667H12.2521V5.39267H12.2651C12.4713 5.36895 12.6799 5.40615 12.8651 5.49967C13.0008 5.608 13.1113 5.74447 13.1891 5.89967C13.2731 6.06301 13.3215 6.24228 13.3311 6.42567C13.3401 6.64567 13.3311 6.82567 13.3311 6.97467C13.3352 7.14631 13.3241 7.31797 13.2981 7.48767C13.2673 7.6619 13.2103 7.83047 13.1291 7.98767C13.0372 8.13383 12.913 8.25698 12.7661 8.34767C12.6427 8.42747 12.4966 8.4647 12.3501 8.45367M17.4301 4.53867H15.0001V9.33867H16.0281V7.43467H17.3281V6.54267H16.0281V5.43067H17.4281V4.53867"
              fill="#464648"
            />
            <path
              d="M21.7812 20.2555C21.7812 20.2555 24.9692 19.6775 24.9692 20.7665C24.9692 21.8555 22.9942 21.4125 21.7812 20.2555ZM19.4242 20.3385C18.9177 20.4504 18.4241 20.6143 17.9512 20.8275L18.3512 19.9275C18.7512 19.0275 19.1662 17.8005 19.1662 17.8005C19.6435 18.6038 20.1988 19.3581 20.8242 20.0525C20.3526 20.1228 19.8852 20.219 19.4242 20.3405V20.3385ZM18.1622 13.8385C18.1622 12.8895 18.4692 12.6305 18.7082 12.6305C18.9472 12.6305 19.2162 12.7455 19.2252 13.5695C19.1473 14.3981 18.9738 15.2148 18.7082 16.0035C18.3443 15.3413 18.156 14.597 18.1612 13.8415L18.1622 13.8385ZM13.5132 24.3545C12.5352 23.7695 15.5642 21.9685 16.1132 21.9105C16.1102 21.9115 14.5372 24.9665 13.5132 24.3545ZM25.9002 20.8955C25.8902 20.7955 25.8002 19.6885 23.8302 19.7355C23.0091 19.7223 22.1883 19.7801 21.3772 19.9085C20.5914 19.1169 19.9148 18.224 19.3652 17.2535C19.7115 16.2529 19.921 15.2102 19.9882 14.1535C19.9592 12.9535 19.6722 12.2655 18.7522 12.2755C17.8322 12.2855 17.6982 13.0905 17.8192 14.2885C17.9377 15.0935 18.1613 15.8796 18.4842 16.6265C18.4842 16.6265 18.0592 17.9495 17.4972 19.2655C16.9352 20.5815 16.5512 21.2715 16.5512 21.2715C15.5739 21.5896 14.6539 22.0623 13.8262 22.6715C13.0022 23.4385 12.6672 24.0275 13.1012 24.6165C13.4752 25.1245 14.7842 25.2395 15.9542 23.7065C16.5758 22.9147 17.1438 22.0822 17.6542 21.2145C17.6542 21.2145 19.4382 20.7255 19.9932 20.5915C20.5482 20.4575 21.2192 20.3515 21.2192 20.3515C21.2192 20.3515 22.8482 21.9905 24.4192 21.9325C25.9902 21.8745 25.9142 20.9935 25.9042 20.8975"
              fill="#DD2025"
            />
            <path
              d="M23.9541 2.07617V7.94917H29.5871L23.9541 2.07617Z"
              fill="#909090"
            />
            <path d="M24.0308 2V7.873H29.6638L24.0308 2Z" fill="#F4F4F4" />
            <path
              d="M8.97497 4.45655H7.66797V9.25655H8.69997V7.63855L8.92797 7.65155C9.14854 7.64775 9.36704 7.60824 9.57497 7.53455C9.75726 7.47182 9.92496 7.37283 10.068 7.24355C10.2124 7.12004 10.326 6.9646 10.4 6.78955C10.5003 6.49805 10.5361 6.18825 10.505 5.88155C10.4987 5.66246 10.4603 5.44547 10.391 5.23755C10.3278 5.08745 10.2342 4.95215 10.1159 4.84024C9.9976 4.72833 9.85732 4.64228 9.70397 4.58755C9.57077 4.53908 9.4331 4.50391 9.29297 4.48255C9.18739 4.46626 9.08079 4.45757 8.97397 4.45655M8.78497 6.75055H8.69597V5.27055H8.88997C8.97515 5.26441 9.06062 5.27748 9.14007 5.30881C9.21952 5.34014 9.29091 5.38892 9.34897 5.45155C9.46929 5.61256 9.53356 5.80855 9.53197 6.00955C9.53197 6.25555 9.53197 6.47855 9.30997 6.63555C9.15004 6.72351 8.96799 6.76312 8.78597 6.74955M12.456 4.44355C12.345 4.44355 12.237 4.45155 12.161 4.45455L11.926 4.46055H11.146V9.26055H12.064C12.4148 9.27016 12.7641 9.2107 13.092 9.08555C13.3559 8.98087 13.5896 8.81213 13.772 8.59455C13.9493 8.375 14.0766 8.11939 14.145 7.84555C14.2235 7.53542 14.2618 7.21646 14.259 6.89655C14.2784 6.51871 14.2491 6.13994 14.172 5.76955C14.0988 5.49691 13.9616 5.24564 13.772 5.03655C13.6232 4.86771 13.441 4.73152 13.237 4.63655C13.0617 4.55546 12.8774 4.4957 12.688 4.45855C12.6126 4.4461 12.5363 4.44041 12.46 4.44155M12.278 8.37855H12.178V5.31455H12.191C12.3971 5.29083 12.6057 5.32803 12.791 5.42155C12.9266 5.52987 13.0372 5.66635 13.115 5.82155C13.1989 5.98488 13.2473 6.16415 13.257 6.34755C13.266 6.56755 13.257 6.74755 13.257 6.89655C13.261 7.06818 13.25 7.23985 13.224 7.40955C13.1931 7.58378 13.1362 7.75235 13.055 7.90955C12.9631 8.0557 12.8389 8.17886 12.692 8.26955C12.5686 8.34935 12.4225 8.38657 12.276 8.37555M17.353 4.46055H14.923V9.26055H15.951V7.35655H17.251V6.46455H15.951V5.35255H17.351V4.46055"
              fill="white"
            />
          </svg>
        );
      case FILE_TYPE.EXCEL.includes(type):
        return (
          <svg
            width="27"
            height="27"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.0999 2.07227L29.6639 7.87227V29.9283H8.87891V30.0003H29.7349V7.94527L24.0999 2.07227Z"
              fill="#909090"
            />
            <path
              d="M24.0311 2H8.80811V29.928H29.6641V7.873L24.0311 2Z"
              fill="#F4F4F4"
            />
            <path
              d="M8.65514 3.5H2.26514V10.327H22.3651V3.5H8.65514Z"
              fill="#7A7B7C"
            />
            <path
              d="M22.472 10.2109H2.39502V3.37891H22.472V10.2109Z"
              fill="#DD2025"
            />
            <path
              d="M9.05212 4.53467H7.74512V9.33467H8.77312V7.71567L9.00012 7.72867C9.22069 7.72488 9.43919 7.68537 9.64712 7.61167C9.82942 7.54897 9.99713 7.44998 10.1401 7.32067C10.2856 7.19749 10.4003 7.04203 10.4751 6.86667C10.5754 6.57517 10.6112 6.26537 10.5801 5.95867C10.5739 5.73958 10.5355 5.5226 10.4661 5.31467C10.403 5.16458 10.3093 5.02927 10.191 4.91737C10.0728 4.80546 9.93247 4.7194 9.77912 4.66467C9.64653 4.61667 9.50953 4.58184 9.37012 4.56067C9.26454 4.54438 9.15794 4.53569 9.05112 4.53467M8.86212 6.82867H8.77312V5.34867H8.96612C9.0513 5.34253 9.13677 5.35561 9.21622 5.38694C9.29566 5.41826 9.36706 5.46704 9.42512 5.52967C9.54544 5.69069 9.60971 5.88668 9.60812 6.08767C9.60812 6.33367 9.60812 6.55667 9.38612 6.71367C9.22618 6.80164 9.04414 6.84224 8.86212 6.82867ZM12.5331 4.52167C12.4221 4.52167 12.3141 4.52967 12.2381 4.53267L12.0001 4.53867H11.2201V9.33867H12.1381C12.4889 9.34829 12.8382 9.28883 13.1661 9.16367C13.43 9.05899 13.6637 8.89025 13.8461 8.67267C14.0235 8.45313 14.1508 8.19751 14.2191 7.92367C14.2977 7.61354 14.336 7.29459 14.3331 6.97467C14.3525 6.59683 14.3233 6.21806 14.2461 5.84767C14.1729 5.57504 14.0358 5.32377 13.8461 5.11467C13.6973 4.94584 13.5152 4.80964 13.3111 4.71467C13.1359 4.63359 12.9516 4.57383 12.7621 4.53667C12.6868 4.52423 12.6105 4.51854 12.5341 4.51967M12.3521 8.45667H12.2521V5.39267H12.2651C12.4713 5.36895 12.6799 5.40615 12.8651 5.49967C13.0008 5.608 13.1113 5.74447 13.1891 5.89967C13.2731 6.06301 13.3215 6.24228 13.3311 6.42567C13.3401 6.64567 13.3311 6.82567 13.3311 6.97467C13.3352 7.14631 13.3241 7.31797 13.2981 7.48767C13.2673 7.6619 13.2103 7.83047 13.1291 7.98767C13.0372 8.13383 12.913 8.25698 12.7661 8.34767C12.6427 8.42747 12.4966 8.4647 12.3501 8.45367M17.4301 4.53867H15.0001V9.33867H16.0281V7.43467H17.3281V6.54267H16.0281V5.43067H17.4281V4.53867"
              fill="#464648"
            />
            <path
              d="M21.7812 20.2555C21.7812 20.2555 24.9692 19.6775 24.9692 20.7665C24.9692 21.8555 22.9942 21.4125 21.7812 20.2555ZM19.4242 20.3385C18.9177 20.4504 18.4241 20.6143 17.9512 20.8275L18.3512 19.9275C18.7512 19.0275 19.1662 17.8005 19.1662 17.8005C19.6435 18.6038 20.1988 19.3581 20.8242 20.0525C20.3526 20.1228 19.8852 20.219 19.4242 20.3405V20.3385ZM18.1622 13.8385C18.1622 12.8895 18.4692 12.6305 18.7082 12.6305C18.9472 12.6305 19.2162 12.7455 19.2252 13.5695C19.1473 14.3981 18.9738 15.2148 18.7082 16.0035C18.3443 15.3413 18.156 14.597 18.1612 13.8415L18.1622 13.8385ZM13.5132 24.3545C12.5352 23.7695 15.5642 21.9685 16.1132 21.9105C16.1102 21.9115 14.5372 24.9665 13.5132 24.3545ZM25.9002 20.8955C25.8902 20.7955 25.8002 19.6885 23.8302 19.7355C23.0091 19.7223 22.1883 19.7801 21.3772 19.9085C20.5914 19.1169 19.9148 18.224 19.3652 17.2535C19.7115 16.2529 19.921 15.2102 19.9882 14.1535C19.9592 12.9535 19.6722 12.2655 18.7522 12.2755C17.8322 12.2855 17.6982 13.0905 17.8192 14.2885C17.9377 15.0935 18.1613 15.8796 18.4842 16.6265C18.4842 16.6265 18.0592 17.9495 17.4972 19.2655C16.9352 20.5815 16.5512 21.2715 16.5512 21.2715C15.5739 21.5896 14.6539 22.0623 13.8262 22.6715C13.0022 23.4385 12.6672 24.0275 13.1012 24.6165C13.4752 25.1245 14.7842 25.2395 15.9542 23.7065C16.5758 22.9147 17.1438 22.0822 17.6542 21.2145C17.6542 21.2145 19.4382 20.7255 19.9932 20.5915C20.5482 20.4575 21.2192 20.3515 21.2192 20.3515C21.2192 20.3515 22.8482 21.9905 24.4192 21.9325C25.9902 21.8745 25.9142 20.9935 25.9042 20.8975"
              fill="#DD2025"
            />
            <path
              d="M23.9541 2.07617V7.94917H29.5871L23.9541 2.07617Z"
              fill="#909090"
            />
            <path d="M24.0308 2V7.873H29.6638L24.0308 2Z" fill="#F4F4F4" />
            <path
              d="M8.97497 4.45655H7.66797V9.25655H8.69997V7.63855L8.92797 7.65155C9.14854 7.64775 9.36704 7.60824 9.57497 7.53455C9.75726 7.47182 9.92496 7.37283 10.068 7.24355C10.2124 7.12004 10.326 6.9646 10.4 6.78955C10.5003 6.49805 10.5361 6.18825 10.505 5.88155C10.4987 5.66246 10.4603 5.44547 10.391 5.23755C10.3278 5.08745 10.2342 4.95215 10.1159 4.84024C9.9976 4.72833 9.85732 4.64228 9.70397 4.58755C9.57077 4.53908 9.4331 4.50391 9.29297 4.48255C9.18739 4.46626 9.08079 4.45757 8.97397 4.45655M8.78497 6.75055H8.69597V5.27055H8.88997C8.97515 5.26441 9.06062 5.27748 9.14007 5.30881C9.21952 5.34014 9.29091 5.38892 9.34897 5.45155C9.46929 5.61256 9.53356 5.80855 9.53197 6.00955C9.53197 6.25555 9.53197 6.47855 9.30997 6.63555C9.15004 6.72351 8.96799 6.76312 8.78597 6.74955M12.456 4.44355C12.345 4.44355 12.237 4.45155 12.161 4.45455L11.926 4.46055H11.146V9.26055H12.064C12.4148 9.27016 12.7641 9.2107 13.092 9.08555C13.3559 8.98087 13.5896 8.81213 13.772 8.59455C13.9493 8.375 14.0766 8.11939 14.145 7.84555C14.2235 7.53542 14.2618 7.21646 14.259 6.89655C14.2784 6.51871 14.2491 6.13994 14.172 5.76955C14.0988 5.49691 13.9616 5.24564 13.772 5.03655C13.6232 4.86771 13.441 4.73152 13.237 4.63655C13.0617 4.55546 12.8774 4.4957 12.688 4.45855C12.6126 4.4461 12.5363 4.44041 12.46 4.44155M12.278 8.37855H12.178V5.31455H12.191C12.3971 5.29083 12.6057 5.32803 12.791 5.42155C12.9266 5.52987 13.0372 5.66635 13.115 5.82155C13.1989 5.98488 13.2473 6.16415 13.257 6.34755C13.266 6.56755 13.257 6.74755 13.257 6.89655C13.261 7.06818 13.25 7.23985 13.224 7.40955C13.1931 7.58378 13.1362 7.75235 13.055 7.90955C12.9631 8.0557 12.8389 8.17886 12.692 8.26955C12.5686 8.34935 12.4225 8.38657 12.276 8.37555M17.353 4.46055H14.923V9.26055H15.951V7.35655H17.251V6.46455H15.951V5.35255H17.351V4.46055"
              fill="white"
            />
          </svg>
        );
      case FILE_TYPE.WORD.includes(type):
        return (
          <svg
            width="27"
            height="27"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.0999 2.07227L29.6639 7.87227V29.9283H8.87891V30.0003H29.7349V7.94527L24.0999 2.07227Z"
              fill="#909090"
            />
            <path
              d="M24.0311 2H8.80811V29.928H29.6641V7.873L24.0311 2Z"
              fill="#F4F4F4"
            />
            <path
              d="M8.65514 3.5H2.26514V10.327H22.3651V3.5H8.65514Z"
              fill="#7A7B7C"
            />
            <path
              d="M22.472 10.2109H2.39502V3.37891H22.472V10.2109Z"
              fill="#DD2025"
            />
            <path
              d="M9.05212 4.53467H7.74512V9.33467H8.77312V7.71567L9.00012 7.72867C9.22069 7.72488 9.43919 7.68537 9.64712 7.61167C9.82942 7.54897 9.99713 7.44998 10.1401 7.32067C10.2856 7.19749 10.4003 7.04203 10.4751 6.86667C10.5754 6.57517 10.6112 6.26537 10.5801 5.95867C10.5739 5.73958 10.5355 5.5226 10.4661 5.31467C10.403 5.16458 10.3093 5.02927 10.191 4.91737C10.0728 4.80546 9.93247 4.7194 9.77912 4.66467C9.64653 4.61667 9.50953 4.58184 9.37012 4.56067C9.26454 4.54438 9.15794 4.53569 9.05112 4.53467M8.86212 6.82867H8.77312V5.34867H8.96612C9.0513 5.34253 9.13677 5.35561 9.21622 5.38694C9.29566 5.41826 9.36706 5.46704 9.42512 5.52967C9.54544 5.69069 9.60971 5.88668 9.60812 6.08767C9.60812 6.33367 9.60812 6.55667 9.38612 6.71367C9.22618 6.80164 9.04414 6.84224 8.86212 6.82867ZM12.5331 4.52167C12.4221 4.52167 12.3141 4.52967 12.2381 4.53267L12.0001 4.53867H11.2201V9.33867H12.1381C12.4889 9.34829 12.8382 9.28883 13.1661 9.16367C13.43 9.05899 13.6637 8.89025 13.8461 8.67267C14.0235 8.45313 14.1508 8.19751 14.2191 7.92367C14.2977 7.61354 14.336 7.29459 14.3331 6.97467C14.3525 6.59683 14.3233 6.21806 14.2461 5.84767C14.1729 5.57504 14.0358 5.32377 13.8461 5.11467C13.6973 4.94584 13.5152 4.80964 13.3111 4.71467C13.1359 4.63359 12.9516 4.57383 12.7621 4.53667C12.6868 4.52423 12.6105 4.51854 12.5341 4.51967M12.3521 8.45667H12.2521V5.39267H12.2651C12.4713 5.36895 12.6799 5.40615 12.8651 5.49967C13.0008 5.608 13.1113 5.74447 13.1891 5.89967C13.2731 6.06301 13.3215 6.24228 13.3311 6.42567C13.3401 6.64567 13.3311 6.82567 13.3311 6.97467C13.3352 7.14631 13.3241 7.31797 13.2981 7.48767C13.2673 7.6619 13.2103 7.83047 13.1291 7.98767C13.0372 8.13383 12.913 8.25698 12.7661 8.34767C12.6427 8.42747 12.4966 8.4647 12.3501 8.45367M17.4301 4.53867H15.0001V9.33867H16.0281V7.43467H17.3281V6.54267H16.0281V5.43067H17.4281V4.53867"
              fill="#464648"
            />
            <path
              d="M21.7812 20.2555C21.7812 20.2555 24.9692 19.6775 24.9692 20.7665C24.9692 21.8555 22.9942 21.4125 21.7812 20.2555ZM19.4242 20.3385C18.9177 20.4504 18.4241 20.6143 17.9512 20.8275L18.3512 19.9275C18.7512 19.0275 19.1662 17.8005 19.1662 17.8005C19.6435 18.6038 20.1988 19.3581 20.8242 20.0525C20.3526 20.1228 19.8852 20.219 19.4242 20.3405V20.3385ZM18.1622 13.8385C18.1622 12.8895 18.4692 12.6305 18.7082 12.6305C18.9472 12.6305 19.2162 12.7455 19.2252 13.5695C19.1473 14.3981 18.9738 15.2148 18.7082 16.0035C18.3443 15.3413 18.156 14.597 18.1612 13.8415L18.1622 13.8385ZM13.5132 24.3545C12.5352 23.7695 15.5642 21.9685 16.1132 21.9105C16.1102 21.9115 14.5372 24.9665 13.5132 24.3545ZM25.9002 20.8955C25.8902 20.7955 25.8002 19.6885 23.8302 19.7355C23.0091 19.7223 22.1883 19.7801 21.3772 19.9085C20.5914 19.1169 19.9148 18.224 19.3652 17.2535C19.7115 16.2529 19.921 15.2102 19.9882 14.1535C19.9592 12.9535 19.6722 12.2655 18.7522 12.2755C17.8322 12.2855 17.6982 13.0905 17.8192 14.2885C17.9377 15.0935 18.1613 15.8796 18.4842 16.6265C18.4842 16.6265 18.0592 17.9495 17.4972 19.2655C16.9352 20.5815 16.5512 21.2715 16.5512 21.2715C15.5739 21.5896 14.6539 22.0623 13.8262 22.6715C13.0022 23.4385 12.6672 24.0275 13.1012 24.6165C13.4752 25.1245 14.7842 25.2395 15.9542 23.7065C16.5758 22.9147 17.1438 22.0822 17.6542 21.2145C17.6542 21.2145 19.4382 20.7255 19.9932 20.5915C20.5482 20.4575 21.2192 20.3515 21.2192 20.3515C21.2192 20.3515 22.8482 21.9905 24.4192 21.9325C25.9902 21.8745 25.9142 20.9935 25.9042 20.8975"
              fill="#DD2025"
            />
            <path
              d="M23.9541 2.07617V7.94917H29.5871L23.9541 2.07617Z"
              fill="#909090"
            />
            <path d="M24.0308 2V7.873H29.6638L24.0308 2Z" fill="#F4F4F4" />
            <path
              d="M8.97497 4.45655H7.66797V9.25655H8.69997V7.63855L8.92797 7.65155C9.14854 7.64775 9.36704 7.60824 9.57497 7.53455C9.75726 7.47182 9.92496 7.37283 10.068 7.24355C10.2124 7.12004 10.326 6.9646 10.4 6.78955C10.5003 6.49805 10.5361 6.18825 10.505 5.88155C10.4987 5.66246 10.4603 5.44547 10.391 5.23755C10.3278 5.08745 10.2342 4.95215 10.1159 4.84024C9.9976 4.72833 9.85732 4.64228 9.70397 4.58755C9.57077 4.53908 9.4331 4.50391 9.29297 4.48255C9.18739 4.46626 9.08079 4.45757 8.97397 4.45655M8.78497 6.75055H8.69597V5.27055H8.88997C8.97515 5.26441 9.06062 5.27748 9.14007 5.30881C9.21952 5.34014 9.29091 5.38892 9.34897 5.45155C9.46929 5.61256 9.53356 5.80855 9.53197 6.00955C9.53197 6.25555 9.53197 6.47855 9.30997 6.63555C9.15004 6.72351 8.96799 6.76312 8.78597 6.74955M12.456 4.44355C12.345 4.44355 12.237 4.45155 12.161 4.45455L11.926 4.46055H11.146V9.26055H12.064C12.4148 9.27016 12.7641 9.2107 13.092 9.08555C13.3559 8.98087 13.5896 8.81213 13.772 8.59455C13.9493 8.375 14.0766 8.11939 14.145 7.84555C14.2235 7.53542 14.2618 7.21646 14.259 6.89655C14.2784 6.51871 14.2491 6.13994 14.172 5.76955C14.0988 5.49691 13.9616 5.24564 13.772 5.03655C13.6232 4.86771 13.441 4.73152 13.237 4.63655C13.0617 4.55546 12.8774 4.4957 12.688 4.45855C12.6126 4.4461 12.5363 4.44041 12.46 4.44155M12.278 8.37855H12.178V5.31455H12.191C12.3971 5.29083 12.6057 5.32803 12.791 5.42155C12.9266 5.52987 13.0372 5.66635 13.115 5.82155C13.1989 5.98488 13.2473 6.16415 13.257 6.34755C13.266 6.56755 13.257 6.74755 13.257 6.89655C13.261 7.06818 13.25 7.23985 13.224 7.40955C13.1931 7.58378 13.1362 7.75235 13.055 7.90955C12.9631 8.0557 12.8389 8.17886 12.692 8.26955C12.5686 8.34935 12.4225 8.38657 12.276 8.37555M17.353 4.46055H14.923V9.26055H15.951V7.35655H17.251V6.46455H15.951V5.35255H17.351V4.46055"
              fill="white"
            />
          </svg>
        );
      case FILE_TYPE.CSV.includes(type):
        return (
          <svg
            width="27"
            height="27"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.25 35H8.75C8.41848 35 8.10054 34.8683 7.86612 34.6339C7.6317 34.3995 7.5 34.0815 7.5 33.75V6.25C7.5 5.91848 7.6317 5.60054 7.86612 5.36612C8.10054 5.1317 8.41848 5 8.75 5H23.75L32.5 13.75V33.75C32.5 34.0815 32.3683 34.3995 32.1339 34.6339C31.8995 34.8683 31.5815 35 31.25 35Z"
              stroke="#0751A1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.75 5V13.75H32.5"
              stroke="#0751A1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.625 24.375L20 28.75L24.375 24.375"
              stroke="#0751A1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20 18.75V28.75"
              stroke="#0751A1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return (
          <svg
            id="Layer_1"
            fill="#fff"
            enableBackground="new 0 0 64 64"
            height="15"
            viewBox="0 0 64 64"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <g id="ARC_176_">
                <g>
                  <path d="m36.243 29.758c-.512 0-1.024-.195-1.414-.586-3.119-3.119-8.194-3.12-11.314 0-.78.781-2.048.781-2.828 0-.781-.781-.781-2.047 0-2.828 4.679-4.68 12.292-4.679 16.97 0 .781.781.781 2.047 0 2.828-.39.391-.903.586-1.414.586z" />
                </g>
              </g>
              <g id="ARC_175_">
                <g>
                  <path d="m34.829 41.167c-3.073 0-6.146-1.17-8.485-3.509-.781-.781-.781-2.047 0-2.828.78-.781 2.048-.781 2.828 0 3.119 3.119 8.194 3.12 11.314 0 .78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-2.34 2.339-5.413 3.509-8.485 3.509z" />
                </g>
              </g>
              <g id="LINE_273_">
                <g>
                  <path d="m41.899 38.243c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l11.172-11.172c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-11.172 11.172c-.39.391-.902.586-1.414.586z" />
                </g>
              </g>
              <g id="LINE_272_">
                <g>
                  <path d="m25.071 55.071c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l6.245-6.245c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-6.245 6.245c-.39.391-.902.586-1.414.586z" />
                </g>
              </g>
              <g id="LINE_271_">
                <g>
                  <path d="m10.929 40.929c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l11.172-11.171c.781-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-11.172 11.171c-.391.39-.903.586-1.414.586z" />
                </g>
              </g>
              <g id="LINE_270_">
                <g>
                  <path d="m32.684 19.175c-.512 0-1.023-.195-1.414-.585-.781-.781-.781-2.047 0-2.829l6.245-6.246c.781-.781 2.047-.781 2.829 0 .781.781.781 2.047 0 2.829l-6.245 6.246c-.391.389-.904.585-1.415.585z" />
                </g>
              </g>
              <g id="ARC_174_">
                <g>
                  <path d="m18 57.935c-3.093 0-6.186-1.15-8.485-3.45-4.6-4.6-4.6-12.371 0-16.971.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-3.066 3.066-3.066 8.248 0 11.314s8.248 3.066 11.314 0c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-2.299 2.301-5.392 3.451-8.485 3.451z" />
                </g>
              </g>
              <g id="ARC_173_">
                <g>
                  <path d="m53.071 27.071c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828 3.066-3.066 3.066-8.248 0-11.314s-8.248-3.066-11.314 0c-.78.781-2.048.781-2.828 0-.781-.781-.781-2.047 0-2.828 4.6-4.6 12.371-4.6 16.971 0s4.6 12.371 0 16.971c-.391.39-.903.585-1.415.585z" />
                </g>
              </g>
            </g>
          </svg>
        );
    }
  };

  const onFileSelection = (fileName, type) => {
    if (type) {
      setSelectedFile({ loading: true, file: null });
      getSignedFile({ fileName })
        .then((response) => {
          setSelectedFile({ loading: false, file: response.result });
        })
        .catch(() => {
          setSelectedFile({ loading: false, file: null });
        });
    } else setSelectedFile({ loading: false, file: fileName });
  };

  const onDeleteFile = (file, fileIndex) => {
    setLoading(true);
    const filename = file?.split("/").pop();
    if (filename) {
      deleteChatFile({ filename })
        .then((res) => {
          const list = fileList.slice();
          list.splice(fileIndex, 1);
          setFileList(list);
          showAlert(
            `File ${constant.MESSAGES.DELETED_MESSAGE}`,
            ToastType.SUCCESS,
          );
        })
        .catch((ex) => showAlert(ex?.message || ex, ToastType.ERROR))
        .finally(() => setLoading(false));
    }
  };

  const renderFileList = () => {
    if (fileList.length)
      return fileList.map((file, fileIndex) => {
        const type = file.split("/").pop()?.split(".")?.pop();
        return (
          <li key={fileIndex} className="d-flex justify-content-between">
            <div className="d-flex">
              <div>{getFileImage(type)}</div>
              <div
                className="ml-2 upload-item-text-wrap"
                onClick={() => onFileSelection(file, type)}
              >
                {file}
              </div>
            </div>
            <Link onClick={() => onDeleteFile(file, fileIndex)}>
              <FontAwesomeIcon icon={faTrashCan} className="text-danger" />
            </Link>
          </li>
        );
      });
    return <li>No Data available</li>;
  };

  const renderModalContent = () => {
    if (selectedFile && selectedFile.file && !selectedFile.loading)
      return <iframe src={selectedFile.file} className="file-iframe" />;
    if (selectedFile && !selectedFile.file && !selectedFile.loading)
      return <p> No data available</p>;
    return (
      <div className="d-flex justify-content-center">
        <CustomLoader />
      </div>
    );
  };

  const renderFileModal = () => {
    return (
      <ModalComponent
        full
        title="Chatbot Files"
        show={selectedFile}
        handleClose={() => setSelectedFile(null)}
      >
        {renderModalContent()}
      </ModalComponent>
    );
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      setNewFile(e.target.files[0]);
    }
  };

  const getFileType = (file) => {
    if (file.type.includes("text")) return FILE_TYPE.TXT[0].toUpperCase();
    if (file.type.includes("pdf")) return FILE_TYPE.PDF[0].toUpperCase();
    if (file.type.includes("csv")) return FILE_TYPE.CSV[0].toUpperCase();
  };

  const onFileConfirm = () => {
    const formdata = new FormData();
    formdata.append("key", getFileType(newFile));
    formdata.append("file", newFile);
    uploadFiles(formdata);
  };

  const uploadFiles = (formdata) => {
    setLoading(true);
    uploadChatbotFile(formdata)
      .then(() => {
        showAlert(`File ${constant.MESSAGES.ADDED_MESSAGE}`, ToastType.SUCCESS);
        setRefresh(!refresh);
      })
      .catch((e) => setLoading(false));
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      const formdata = new FormData();
      formdata.append("key", FILE_TYPE.WEB);
      formdata.append("value", url);
      uploadFiles(formdata);
    }
  };

  const render = () => {
    return (
      <>
        <div className="chatbotsidebar px-3 py-4">
          <div className="w-100 text-center mb-3 d-flex align-items-baseline gap-1">
            <div
              className="file-upload-wrapper4 mb-2"
              data-text="UPLOAD NEW DOC"
            >
              <input
                name="file-upload-field2"
                type="file"
                className="file-upload-field2"
                ref={fileRef}
                accept=".pdf,.csv,.txt"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="d-flex border-bottom border-primary align-items-center">
            <input
              type="text"
              className="form-control1 w-100 border-0 text-white bg-transparent"
              placeholder="UPLOAD FROM LINK"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              onKeyUp={handleKeyUp}
            />
            <div className="pl-2">
              <svg
                id="Layer_1"
                fill="#fff"
                enableBackground="new 0 0 64 64"
                height="15"
                viewBox="0 0 64 64"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <g id="ARC_176_">
                    <g>
                      <path d="m36.243 29.758c-.512 0-1.024-.195-1.414-.586-3.119-3.119-8.194-3.12-11.314 0-.78.781-2.048.781-2.828 0-.781-.781-.781-2.047 0-2.828 4.679-4.68 12.292-4.679 16.97 0 .781.781.781 2.047 0 2.828-.39.391-.903.586-1.414.586z" />
                    </g>
                  </g>
                  <g id="ARC_175_">
                    <g>
                      <path d="m34.829 41.167c-3.073 0-6.146-1.17-8.485-3.509-.781-.781-.781-2.047 0-2.828.78-.781 2.048-.781 2.828 0 3.119 3.119 8.194 3.12 11.314 0 .78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-2.34 2.339-5.413 3.509-8.485 3.509z" />
                    </g>
                  </g>
                  <g id="LINE_273_">
                    <g>
                      <path d="m41.899 38.243c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l11.172-11.172c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-11.172 11.172c-.39.391-.902.586-1.414.586z" />
                    </g>
                  </g>
                  <g id="LINE_272_">
                    <g>
                      <path d="m25.071 55.071c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l6.245-6.245c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-6.245 6.245c-.39.391-.902.586-1.414.586z" />
                    </g>
                  </g>
                  <g id="LINE_271_">
                    <g>
                      <path d="m10.929 40.929c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l11.172-11.171c.781-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-11.172 11.171c-.391.39-.903.586-1.414.586z" />
                    </g>
                  </g>
                  <g id="LINE_270_">
                    <g>
                      <path d="m32.684 19.175c-.512 0-1.023-.195-1.414-.585-.781-.781-.781-2.047 0-2.829l6.245-6.246c.781-.781 2.047-.781 2.829 0 .781.781.781 2.047 0 2.829l-6.245 6.246c-.391.389-.904.585-1.415.585z" />
                    </g>
                  </g>
                  <g id="ARC_174_">
                    <g>
                      <path d="m18 57.935c-3.093 0-6.186-1.15-8.485-3.45-4.6-4.6-4.6-12.371 0-16.971.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-3.066 3.066-3.066 8.248 0 11.314s8.248 3.066 11.314 0c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-2.299 2.301-5.392 3.451-8.485 3.451z" />
                    </g>
                  </g>
                  <g id="ARC_173_">
                    <g>
                      <path d="m53.071 27.071c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828 3.066-3.066 3.066-8.248 0-11.314s-8.248-3.066-11.314 0c-.78.781-2.048.781-2.828 0-.781-.781-.781-2.047 0-2.828 4.6-4.6 12.371-4.6 16.971 0s4.6 12.371 0 16.971c-.391.39-.903.585-1.415.585z" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <h3 className="h6 font-weight-bold mt-4 text-white">
            Uploaded Docs.
          </h3>
          <ul className="chatbotsidebarlist mt-4 mx-h-30 p-1">
            {loading && (
              <div className="d-flex justify-content-center">
                <CustomLoader />
              </div>
            )}
            {renderFileList()}
          </ul>
        </div>
        {renderFileModal()}
      </>
    );
  };

  return render();
};

export default ChatbotFiles;
