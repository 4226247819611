import React, { useEffect, useState } from "react";
import ChatbotHistory from "./ChatbotHistory";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import ChatQueyResult from "./ChatQueryResult";
import ChatbotFiles from "./ChatbotFiles";
import AskMeRightPanel from "./AskMeRightPanel";
import images from "../../../helper/images";

const ChatbotResult = () => {
  const {
    updateCurrentQuery,
    currentQuery,
    getAnswerForQuery,
    queryHistory,
    updateShowModal,
    queryTasks,
    queryWorkflows,
    clearAskMe,
  } = useAIAskMeContext();
  const [loading, setLoading] = useState({ workFlow: false, doc: false });
  const [query, setQuery] = useState("");
  const [force, setForce] = useState(false);
  const [checkedModel, setCheckedModel] = useState(true);
  const [chatGpt, setChatGpt] = useState(true);
  const [checkedDoc, setCheckedDoc] = useState(true);

  useEffect(() => {
    if (currentQuery) {
      setLoading({ workFlow: true, doc: true });
      setQuery(currentQuery);
      getAnswerForQuery(chatGpt, () => {
        setLoading({ workFlow: false, doc: true });
      });
    }
  }, [currentQuery, force]);

  const renderDefaultQuestionList = () => {
    const shortHistory = queryHistory.slice(0, 4);
    return (
      <ul className="question-list">
        {shortHistory.map((item, qIndex) => (
          <li key={`query-list-${qIndex}`}>
            <button
              onClick={() => {
                updateCurrentQuery(item.id);
              }}
            >
              {item.id}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const onQuerySubmit = (e) => {
    e.preventDefault();
    if (query !== currentQuery) {
      updateCurrentQuery(query);
    } else {
      setForce(!force);
    }
  };

  const renderChatResult = () => {
    if (queryTasks.length || queryWorkflows.length)
      return (
        <ChatQueyResult
          checkedDoc={checkedDoc}
          checkedModel={checkedModel}
          chatGpt={chatGpt}
          stopLoader={() => {
            setLoading({ ...loading, doc: false });
          }}
        />
      );
    // return renderDefaultQuestionList()
  };

  const renderCloseModal = () => {
    return (
      <div
        className="crossdismiss"
        onClick={() => {
          updateShowModal(false);
          clearAskMe();
        }}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 30L30 10M10 10L30 30"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };

  const renderInlineLoader = () => {
    return (
      <div className="ask-me-loader-container">
        <img src={images.loaderGif} width="30px" alt="" />
      </div>
    );
  };

  const renderChatContent = () => {
    return (
      <div className="p-5">
        <form noValidate onSubmit={onQuerySubmit}>
          <div className="d-flex align-items-center">
            <div className="border-bottom py-2 w-100 justify-content-between d-flex align-items-center">
              <input
                type="text"
                onChange={(e) => setQuery(e.target.value.slice())}
                value={query}
                className="askmeanything2 bg-none text-white border-0 w-75"
                placeholder="Ask Me Anything..."
              />

              <div className="checktasks text-center chatGpt">
                <label className="form-check-label d-block" htmlFor="chatGpt">
                  <input
                    type="checkbox"
                    className="form-check-input checkInputClick"
                    id="chatGpt"
                    name="chatGpt"
                    checked={chatGpt}
                    onChange={(e) => setChatGpt(!chatGpt)}
                  />
                  <span className="tskchk">
                    <small className="text-white">
                      <i>Powered by ChatGPT</i>
                    </small>
                    <svg width="34" height="35" viewBox="0 0 39 40" fill="none">
                      <path
                        d="M19.2193 3.83988L19.2664 3.83753L19.3123 3.82642C19.3856 3.80864 19.4606 3.7994 19.5358 3.79883C22.8074 3.79977 25.5 6.56518 25.5 10.0005V20.0005C25.5 23.413 22.7968 26.1672 19.5 26.1672C16.2033 26.1672 13.5 23.413 13.5 20.0005V10.0008C13.5021 8.41213 14.0997 6.88729 15.1656 5.74127C16.2312 4.59557 17.6829 3.91628 19.2193 3.83988Z"
                        stroke="#fff"
                      ></path>
                      <path
                        d="M18.375 33.2183V32.7786L17.9389 32.7224C11.9502 31.9506 7.24628 26.8137 7.00937 20.5H9.26148C9.51682 26.049 13.9992 30.5 19.5 30.5C25.0008 30.5 29.4832 26.049 29.7385 20.5H31.9906C31.7538 26.8151 27.0499 31.9505 21.0611 32.7224L20.625 32.7786V33.2183V36.1667H18.375V33.2183Z"
                        stroke="#fff"
                      ></path>
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </form>
        <div className="row justify-content-center mt-3">
          <div className="col-md-4">
            <div className="checktasks text-center">
              <label className="form-check-label d-block" htmlFor="check001">
                <input
                  type="checkbox"
                  className="form-check-input checkInputClick"
                  id="check001"
                  name="option1111"
                  checked={checkedModel}
                  onChange={(e) => setCheckedModel(!checkedModel)}
                />
                <span className="tskchk">
                  <h4 className="mb-2 h6 text-white">AI Models & Tasks</h4>
                </span>
              </label>
              {loading.workFlow && checkedModel && renderInlineLoader()}
            </div>
          </div>
          <div className="col-md-4">
            <div className="checktasks text-center">
              <label className="form-check-label d-block" htmlFor="check002">
                <input
                  type="checkbox"
                  className="form-check-input checkInputClick"
                  id="check002"
                  name="option2222"
                  checked={checkedDoc}
                  onChange={(e) => setCheckedDoc(!checkedDoc)}
                />
                <span className="tskchk">
                  <h4 className="mb-2 h6 text-white">Info & Docs.</h4>
                </span>
              </label>
              {loading.doc && checkedDoc && renderInlineLoader()}
            </div>
          </div>
        </div>
        <div className="mt-3">{renderChatResult()}</div>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            {renderCloseModal()}
            <div className="col-md-2 px-0">
              <div className="chtbothstrybox text-white py-3">
                <div className="px-3">
                  <ChatbotFiles />
                </div>
                <div className="border-top"></div>
                <div className="px-3">
                  <ChatbotHistory />
                </div>
              </div>
            </div>
            <div className="col-md-8 px-0">{renderChatContent()}</div>
            <div className="col-md-2 px-0">
              <AskMeRightPanel />
            </div>
          </div>
        </div>
      </>
    );
  };
  return render();
};

export default ChatbotResult;
