import React, { useState } from "react";
import { getUniqueModelIds, getUniqueTasks } from "../../../helper/helper";
import { useAIModelContext } from "./../../../context/ai-model.context";
import AIModelDescription from "./AIModelDescription";

const AIModelList = () => {
  const { models } = useAIModelContext();
  const renderModelList = () => {
    if (models && models.length) {
      const modelIds = getUniqueModelIds(models);
      return modelIds.map((modelId, index) => (
        <AIModelDescription key={`modeld-desc-${index}`} modelId={modelId} />
      ));
    }
  };

  const render = () => {
    return (
      <div className="col-md-2 px-md-0 rightbar-outer">
        <div className="rightbar bg-white">{renderModelList()}</div>
      </div>
    );
  };
  return render();
};

export default AIModelList;
