import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import {
  getRegressionData,
  getTimeSeriesPrediction,
  postRegressionData,
  postTimeSeriesPrediction,
  uploadRegressionData,
} from "../../../../../service/ai-model.service";
import CustomLoader from "../../../../common/CustomLoader";
import {
  downloadCSV,
  formatDate,
  sendKeyToLast,
} from "../../../../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowDown,
  faArrowUp,
  faCheck,
  faDownload,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../../../../context/app.context";
import constant, { ModelType, ToastType } from "../../../../../helper/constant";

const RegressionTable = () => {
  const [tableData, setTableData] = useState({ columns: [], data: [] });
  const [search, setSearch] = useState("");
  const [bulk, setBulk] = useState("");
  const [disableNew, setDisableNew] = useState(false);
  const params = useParams();
  const { selectedPredictionModel, setPredictionData } = useAIModelContext();
  const { updateLoader, isLoading, showAlert } = useAppContext();

  useEffect(() => {
    getFreshData();
  }, []);

  const getFreshData = () => {
    if (selectedPredictionModel && params.id) {
      const { modelId } = selectedPredictionModel;
      if (
        modelId === ModelType.TabularRegression ||
        modelId === ModelType.TabularClassification
      ) {
        getRegressionTableData();
      }
      if (modelId === ModelType.TimeSerialTabular) {
        getRegressionTimeSeriesData();
      }
    }
  };
  const getRegressionTableData = () => {
    if (selectedPredictionModel && params.id) {
      const { taskId, modelId } = selectedPredictionModel;
      updateLoader(true);
      getRegressionData(params.id, taskId)
        .then((data) => {
          if (data.length) {
            const formated = data.map((item) => {
              if (item.prediction) {
                const result = JSON.parse(item.prediction);
                try {
                  const prediction =
                    modelId === ModelType.TabularClassification
                      ? result?.predictions[0]?.classes
                      : result?.predictions[0]?.value;
                  return { ...item, prediction };
                } catch (ex) {
                  return { ...item, prediction: "" };
                }
              } else {
                return { ...item, prediction: "" };
              }
            });
            const shiftArray =
              modelId === ModelType.TabularClassification
                ? ["prediction", "confidence"]
                : ["prediction"];
            let columns = sendKeyToLast(Object.keys(formated[0]), shiftArray);
            setTableData({ columns, data: formated });
            setPredictionData(formated);
          }
        })
        .catch((ex) => showAlert(ex), ToastType.ERROR)
        .finally(() => {
          updateLoader(false);
        });
    }
  };

  const getRegressionTimeSeriesData = () => {
    if (selectedPredictionModel && params.id) {
      const { taskId } = selectedPredictionModel;
      updateLoader(true);
      getTimeSeriesPrediction(params.id, taskId)
        .then((data) => {
          if (data && data.length) {
            const columns = sendKeyToLast(Object.keys(data[0]), [
              "predicted_SalesQty",
              "predicted_on_SalesDate",
            ]);
            console.log(columns);
            setTableData({ columns, data });
            setPredictionData(data);
          }
          updateLoader(false);
        })
        .catch((ex) => showAlert(ex), ToastType.ERROR)
        .finally(() => {
          updateLoader(false);
        });
    }
  };

  useEffect(() => {
    if (bulk) {
      const { taskId, modelId } = selectedPredictionModel;
      const formData = new FormData();
      formData.append("csv", bulk);
      if (
        modelId === ModelType.TabularRegression ||
        modelId === ModelType.TabularClassification
      ) {
        uploadBulkRegression(formData);
      }
      if (modelId === ModelType.TimeSerialTabular) {
        uploadBulkTimeSeries(formData);
      }
    }
  }, [bulk]);

  const uploadBulkRegression = (formData) => {
    updateLoader(true);
    const { taskId } = selectedPredictionModel;
    uploadRegressionData(params.id, taskId, formData)
      .then((data) => {
        if (data) getFreshData();
      })
      .catch((ex) => {
        updateLoader(false);
        showAlert(ex, ToastType.ERROR);
      })
      .finally(() => setBulk(null));
  };

  const uploadBulkTimeSeries = (formData) => {
    const { taskId } = selectedPredictionModel;
    updateLoader(true);
    postTimeSeriesPrediction(params.id, taskId, formData)
      .then((data) => {
        if (data) getFreshData();
      })
      .catch((ex) => {
        updateLoader(false);
        showAlert(ex, ToastType.ERROR);
      })
      .finally(() => setBulk(null));
  };

  // Search change
  useEffect(() => {
    if (search & tableData) {
      const list = tableData.slice();
    }
  }, [search, tableData]);

  const onSort = (col, direction) => {
    const { data } = tableData;
    if (direction) data.sort((a, b) => a[col] - b[col]);
    else data.sort((a, b) => b[col] - a[col]);

    setTableData({ ...tableData, data });
  };

  const renderPredictionsColumns = () => {
    const { columns } = tableData;
    if (columns.length) {
      return (
        <div className="regressionrow regressionheader">
          {columns.map((col, index) => {
            const lblCol = col?.replaceAll("_", " ").toUpperCase();
            return (
              <div key={index} className="regressioncell">
                <ColumnHead title={lblCol} col={col} onSort={onSort} />
              </div>
            );
          })}
        </div>
      );
    }
  };

  const onDeleteRow = () => {
    const data = tableData.data.slice();
    data.splice(0, 1);
    setDisableNew(false);
    setTableData({ ...tableData, data });
  };

  const renderTableRows = () => {
    const { columns, data } = tableData;
    if (data.length)
      return data.map((item, key) => {
        return (
          <TableRow
            key={key}
            item={item}
            columns={columns}
            onSubmitPrediction={submitPredictionRequest}
            onDeleteRow={onDeleteRow}
          />
        );
      });
  };

  const renderPredictions = () => {
    if (tableData.data.length) {
      return (
        <>
          {renderPredictionsColumns()}
          {renderTableRows()}
        </>
      );
    }

    return (
      <div className="row justify-content-center">
        <CustomLoader masked />
      </div>
    );
  };

  const renderSearchbox = () => {
    return (
      <div className="row justify-content-end">
        <div className="col col-sm-12 col-md-12">
          <div className="form-group mb-0 d-flex align-items-center form-control">
            <input
              type="text"
              className="border-0 w-100 px-2"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <svg width="18" height="18" viewBox="0 0 20 20" fill="none">
              <path
                d="M19 19L13 13M1 8C1 8.91925 1.18106 9.82951 1.53284 10.6788C1.88463 11.5281 2.40024 12.2997 3.05025 12.9497C3.70026 13.5998 4.47194 14.1154 5.32122 14.4672C6.1705 14.8189 7.08075 15 8 15C8.91925 15 9.82951 14.8189 10.6788 14.4672C11.5281 14.1154 12.2997 13.5998 12.9497 12.9497C13.5998 12.2997 14.1154 11.5281 14.4672 10.6788C14.8189 9.82951 15 8.91925 15 8C15 7.08075 14.8189 6.1705 14.4672 5.32122C14.1154 4.47194 13.5998 3.70026 12.9497 3.05025C12.2997 2.40024 11.5281 1.88463 10.6788 1.53284C9.82951 1.18106 8.91925 1 8 1C7.08075 1 6.1705 1.18106 5.32122 1.53284C4.47194 1.88463 3.70026 2.40024 3.05025 3.05025C2.40024 3.70026 1.88463 4.47194 1.53284 5.32122C1.18106 6.1705 1 7.08075 1 8Z"
                stroke="#474646"
                strokeOpacity="0.5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  const addNewPredictionRequest = () => {
    let { columns, data } = tableData;
    setDisableNew(true);
    const newdata = {};
    columns.map((item) => (newdata[item] = ""));
    newdata.isEdit = true;
    newdata.prediction = -1;
    data.unshift(newdata);
    setTableData({ ...tableData, data });
  };

  const submitPredictionRequest = (formData) => {
    updateLoader(true);
    const templateId = params.id;
    const { taskId } = selectedPredictionModel;
    postRegressionData(templateId, taskId, formData)
      .then((result) => {
        try {
          if (result.prediction) getFreshData();
        } catch (ex) {
          showAlert(ex, ToastType.ERROR);
        }
      })
      .catch((ex) => showAlert(ex), ToastType.ERROR)
      .finally(() => {
        setDisableNew(false);
        updateLoader(false);
      });
  };

  const onDownloadCSV = () => {
    const { data } = tableData;
    const { task_model } = selectedPredictionModel;
    const datetime = formatDate("", constant.csvDateFormat);
    const fileName = `${task_model}_${datetime}.csv`;
    downloadCSV(data, fileName);
  };

  const onDowloadSampleTemplate = () => {
    const { columns } = tableData;
    const newdata = {};
    columns.map((item) => (newdata[item] = ""));
    const { task_model } = selectedPredictionModel;
    const fileName = `${task_model}_sample_template.csv`;
    downloadCSV([newdata], fileName);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      console.log({ file });
      if (constant.allowedFileExtensions.includes(file.type)) {
        setBulk(e.target.files[0]);
      } else {
        showAlert("Please upload only csv format files.", ToastType.WARNING);
      }
    }
  };

  const renderTableActions = () => {
    const { data } = tableData;
    if (selectedPredictionModel.modelId === ModelType.TimeSerialTabular) {
      return (
        <div className="d-flex justify-content-between">
          <div className="d-flex regressionactions">
            <button
              className="btn1"
              disabled={!data.length}
              onClick={() => onDowloadSampleTemplate()}
            >
              Sample Template <FontAwesomeIcon icon={faDownload} />
            </button>
            <button
              className="btn1"
              disabled={!data.length}
              onClick={() => onDownloadCSV()}
            >
              Download Prediction Report <FontAwesomeIcon icon={faDownload} />
            </button>
          </div>
          <div className="d-flex regressionactions">
            <div className="upload-btn-wrapper">
              <button className="btn1">
                Bulk Upload <FontAwesomeIcon icon={faUpload} />
              </button>
              <input
                type="file"
                name="myfile"
                onChange={handleFileChange}
                accept=".csv"
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex regressionactions">
          <button
            className="btn1"
            disabled={!data.length}
            onClick={() => onDowloadSampleTemplate()}
          >
            Sample Template <FontAwesomeIcon icon={faDownload} />
          </button>
          <button
            className="btn1"
            disabled={!data.length}
            onClick={() => onDownloadCSV()}
          >
            Download Prediction Report <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
        <div className="d-flex regressionactions">
          <button
            className="btn1"
            disabled={disableNew}
            onClick={() => addNewPredictionRequest()}
          >
            <FontAwesomeIcon icon={faAdd} /> New
          </button>
          <div className="upload-btn-wrapper">
            <button className="btn1">
              Bulk Upload <FontAwesomeIcon icon={faUpload} />
            </button>
            <input
              type="file"
              name="myfile"
              onChange={handleFileChange}
              accept=".csv"
            />
          </div>
        </div>
      </div>
    );
  };

  const dynamicWidth = () => {
    if (tableData.columns && tableData.columns.length) {
      if (tableData.columns.length < 8) {
        return "100%";
      }
      return `${tableData.columns.length * 13}%`;
    }
    return "100%";
  };
  const render = () => {
    const width = dynamicWidth();
    return (
      <>
        {isLoading && (
          <div className="full-mask d-flex justify-content-center">
            <CustomLoader masked />
          </div>
        )}
        <div className="regressiontableactions py-2">
          {renderTableActions()}
        </div>
        <div className="regressionsearchbox py-3">{renderSearchbox()}</div>
        <div className="regressiontable" style={{ width }}>
          {renderPredictions()}
        </div>
      </>
    );
  };

  return render();
};

export default RegressionTable;

const TableRow = (props) => {
  const { item, columns = [], onSubmitPrediction, onDeleteRow } = props;
  const render = () => {
    if (item.isEdit) {
      return (
        <NewAddPredictionForm
          columns={columns}
          onSubmitPrediction={onSubmitPrediction}
          onDeleteRow={onDeleteRow}
        />
      );
    }
    return (
      <div className="regressionrow">
        {columns.map((k, rowIndex) => (
          <p key={rowIndex} className="regressioncell">
            {(k === "prediction" || k === "confidence") && item[k]
              ? Number(Number.parseFloat(item[k]).toFixed(2)).toLocaleString()
              : item[k]}
          </p>
        ))}
      </div>
    );
  };
  return render();
};

const ColumnHead = (props) => {
  const { title, col, onSort } = props;
  const [asc, setAsc] = useState(true);

  useEffect(() => {
    onSort(col, asc);
  }, [asc]);

  const render = () => {
    return (
      <div className="regressioncellhead">
        <p className="p-0 py-1 celllabel">{title}</p>
        <Link className="cellsort" onClick={() => setAsc(!asc)}>
          <FontAwesomeIcon icon={asc ? faArrowUp : faArrowDown} />
        </Link>
      </div>
    );
  };

  return render();
};

const NewAddPredictionForm = (props) => {
  const { columns = [], onSubmitPrediction, onDeleteRow } = props;
  const keyValuePairs = columns.map((key, index) => [key, ""]);
  const data = Object.fromEntries(keyValuePairs);
  const [formData, setFormData] = useState(data);
  const [form, setForm] = useState({ error: {} });

  const handleOnChange = (property, value) => {
    const data = { ...formData };
    data[property] = value;
    setFormData(data);
  };
  const handleValidation = () => {
    let formIsValid = true;
    let error = {};
    for (const k in formData) {
      if (k !== "prediction" && k !== "confidence" && !formData[k]) {
        formIsValid = false;
        error[k] = `${k} is required.`;
      }
    }
    setForm({ error });
    return formIsValid;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (handleValidation() && onSubmitPrediction) {
      onSubmitPrediction(formData);
    }
  };

  return (
    <form noValidate className="regressionrow" onSubmit={onFormSubmit}>
      {columns.map((k, rowIndex) => {
        if (k === "prediction") {
          return (
            <div
              className="regressioncell text-center d-flex justify-content-around"
              key={rowIndex}
            >
              <button className="btn-clear" type="submit">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-success"
                  size="md"
                />
              </button>
              <button
                className="btn-clear"
                type="button"
                onClick={() => onDeleteRow()}
              >
                <FontAwesomeIcon
                  className="text-danger"
                  icon={faTrash}
                  size="md"
                />
              </button>
            </div>
          );
        }
        return (
          <div className="regressioncell" key={rowIndex}>
            <input
              type="text"
              className={`form-control ${form.error[k] ? "error-br" : ""}`}
              value={formData[k]}
              readOnly={k === "confidence"}
              onChange={(e) => handleOnChange(k, e.target.value)}
            />
          </div>
        );
      })}
    </form>
  );
};
